
export const statusList = [
    {value: 0, label: "禁用"},
    {value: 1, label: "可用"}
];

export const posStatusList = [
    {value: 0, label: "下线"},
    {value: 1, label: "在线"}
];


export const complainStatusList = [
    {value: 1, label: "未处理"},
    {value: 2, label: "已处理"}
];


export const complainTypeList = [
    {value: 1, label: "职位投诉"},
    {value: 2, label: "简历投诉"},
    {value: 3, label: "直播投诉"}
];

export const readStatusList = [
    {value: 1, label: "未读"},
    {value: 2, label: "已读"}
];

export const workStatusList = [
    {label:"离职-随时到岗",value:1},
    {label:"在职-月内到岗",value:2},
    {label:"在职-考虑机会",value:3},
    {label:"在职-暂不考虑",value:4},
]

export const orderStatusList = [
    {label:"待支付",value:1},
    {label:"已支付",value:3},
]

export const paymentStatusList = [
    {label:"待支付",value:1},
    {label:"已支付",value:3},
]

export const payStatusList = [
    {label:"未完成",value:-1},
    {label:"已完成",value:1},
]

export const payTypeList = [
    {label:"微信",value:1},
    {label:"支付宝",value:2},
]

export const incomeTypeList = [
    {label:"收款",value:1},
    {label:"退款",value:2},
]

export const bizApplyStatusList = [
    {label:"待处理",value:1},
    {label:"被驳回",value:2},
    {label:"已处理",value:3},
]

export const liveInfoStatusList = [
    {label:"正常",value:1},
    {label:"封停",value:3}
]
export const scaleTypeList = [
    {label:"50人以下",value:1},
    {label:"50-99人",value:2},
    {label:"100-499人",value:3},
    {label:"500-999人",value:4},
    {label:"1000-9999人",value:5},
    {label:"10000人以上",value:6},
]

export const restTypeList = [    
    {label:"双休",value:1},
    {label:"单休",value:2},
    {label:"大小周",value:3},
    {label:"其他",value:4},
]

export const genderOpts = [
    { label: '男', value: 1 },
    { label: '女', value: 0 }
];

export const eduSubTypeList = [
    {label:"统招",value:1},
    {label:"非统招",value:2},
]

export const eduGradeList = [
    {label:"无学历要求",value:1},
    {label:"初中及以下",value:2},
    {label:"高中",value:3},
    {label:"中专/中技",value:4,children:eduSubTypeList},
    {label:"大专",value:5,children:eduSubTypeList},
    {label:"本科",value:6,children:eduSubTypeList},
    {label:"硕士",value:7,children:eduSubTypeList},
    {label:"博士",value:8,children:eduSubTypeList},
]

export const expYearList = [
    {label:"无经验要求",value:1},
    {label:"1-3年经验",value:2},
    {label:"3-5年经验",value:3},
    {label:"5-10年经验",value:4},
    {label:"10年以上经验",value:5},
]

export const posTypeList = [
    {label:"全职",value:1},
    {label:"兼职",value:2},
]


export const bizTypeList = [
    {label:"民营",value:1},
    {label:"国企",value:2},
    {label:"合资",value:3},
    {label:"外资",value:4},
    {label:"其他",value:5},
]

export const sysNotifyTypeList = [
    {label:"通用",value:1},
]