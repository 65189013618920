import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';

import {Avatar,Box,Button,Chip,Dialog,Divider,Grid, Paper, Typography} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';

import { getAsyncUserProfileList,getAsyncUserProfileOpt,getAsyncUserProfileEdu,
    getAsyncUserProfileProject,getAsyncUserProfileWork } from '../../store/actions/main/UserListAction';
import {genderOpts,eduGradeList,posTypeList,workStatusList} from '../../utils/SysData'
import { AppActionType } from '../../store/types';
import { fileHost } from '../../config';

const commonUtil = require('../../utils/CommonUtil')

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: "#FFF",
        minWidth: 400
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, ...other } = props;
    return (
        <MuiDialogTitle disabletypography="true" className={classes.root} {...other}>
            <Typography style={{fontSize:"1.5rem"}}>{children}</Typography>
        </MuiDialogTitle>
    );
});


const DialogContent = withStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(2),
        }
    }
})(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    }
}))(MuiDialogActions);

const ProfileModal = (props) => {
    const [profile,setProfile] = useState({})
    const [eduList ,setEduList] = useState([])
    const [workList ,setWorkList] = useState([])
    const [optList ,setOptList] = useState([])
    const [projectList ,setProjectList] = useState([])

    const {appReducer,closeProfileModel} = props;
    
    useEffect(()=>{
        if(appReducer.userId>0){
            getUserProfile(appReducer.userId)
            getUserProfileEdu(appReducer.userId)
            getUserProfileWork(appReducer.userId)
            getUserProfileProject(appReducer.userId)
            getUserProfileOpt(appReducer.userId)
            
        }
    },[])

    const getUserProfile = async (userId)=>{
        const res = await getAsyncUserProfileList({userId});
        if(res && res.list){            
            setProfile(res.list[0])
        }
    }

    const getUserProfileEdu = async (userId)=>{
        const res = await getAsyncUserProfileEdu({userId});
        if(res && res.list){            
            setEduList([...res.list])
        }
    }

    const getUserProfileWork = async (userId)=>{
        const res = await getAsyncUserProfileWork({userId});
        if(res && res.list){            
            setWorkList([...res.list])
        }
    }

    const getUserProfileProject = async (userId)=>{
        const res = await getAsyncUserProfileProject({userId});
        if(res && res.list){            
            setProjectList([...res.list])
        }
    }

    const getUserProfileOpt = async (userId)=>{
        const res = await getAsyncUserProfileOpt({userId});
        if(res && res.list){            
            setOptList([...res.list])
        }
    }

    return (
        <Dialog aria-labelledby="customized-dialog-title" open={appReducer.userId>0} onClose={closeProfileModel} fullWidth>
            <DialogTitle id="customized-dialog-title" style={{display:'flex',justifyContent:'space-between'}}>
                <span>{profile.name} - {profile.userId}</span>
            </DialogTitle>
            <DialogContent dividers>
                <Paper sx={{padding:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align="center">基本信息</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={10}>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={7}>姓名: {profile.name}</Grid>
                                <Grid item xs={5}>性别: {commonUtil.getJsonValue(genderOpts, profile.gender)}</Grid>
                            </Grid>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={7}>电话: {profile.phone}</Grid>
                                <Grid item xs={5}>生日: {commonUtil.getYearMonth(profile.birth)}</Grid>
                            </Grid>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={7}>邮箱: {profile.email}</Grid>
                                <Grid item xs={5}>求职状态: {commonUtil.getJsonValue(workStatusList,profile.current)}</Grid>
                            </Grid>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={7}>居住城市: {profile.currentProvidenceName} - {profile.currentCityName}</Grid>
                                <Grid item xs={5}>学历: {commonUtil.getJsonValue(eduGradeList, profile.eduLevel)}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sx={{display:"flex"}}justifyContent={"flex-end"}>
                        {profile.avatar && profile.avatar !='' && 
                            <Avatar src={"http://"+fileHost+ profile.avatar} sx={{ width: 96, height: 96}}   />}
                        </Grid>
                    </Grid>
                    <Grid sx={{marginBottom:1}} container alignItems="center">
                        <Grid item xs={12}>注册时间: <span>{commonUtil.getDateTime(profile.CreatedAt)}</span></Grid>
                    </Grid>
                    <Grid sx={{marginBottom:1}} container alignItems="center">
                        <Grid item xs={12}>个人优势: </Grid>
                        <Grid item xs={12}><span>{profile.excellent}</span></Grid>
                    </Grid>
                    <Grid sx={{marginBottom:1}} container alignItems="center">
                        <Grid item xs={12}>技能与证书: </Grid>
                        <Grid item xs={12} sx={{marginTop:1}}>{profile.certs !=null && profile.certs.length>0 && profile.certs.map((item,index)=>{
                            return <Chip key={"_profile_cert_"+index} sx={{marginRight:2}} label={item} />
                        })}</Grid>
                    </Grid>
                    
                </Paper>
                <Paper sx={{padding:2,marginTop:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align='center'>求职意向</Typography>
                        </Grid>
                    </Grid>
                    {optList && optList.length>0 && optList.map((item,index)=>{
                        return (
                            <div key={"_profile_opt_"+item.id}>
                                <Box sx={{padding:1}}>
                                    <Grid mx={{marginTop:1}} container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>职位: {item.jobTypeSubName}</Grid>
                                        <Grid item>{item.salaryMin}K 至 {item.salaryMax}K</Grid>
                                    </Grid>
                                    <Grid mx={{marginBottom:1}}container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>地点: {item.providenceName}-{item.cityName}-{item.areaName}</Grid>
                                        <Grid item>类型: {commonUtil.getJsonValue(posTypeList,item.optType)}</Grid>
                                    </Grid>
                                </Box>
                                <Divider/>
                            </div>
                        )
                    })}
                </Paper>
                <Paper sx={{padding:2,marginTop:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align='center'>教育经历</Typography>
                        </Grid>
                    </Grid>
                    {eduList && eduList.length>0 && eduList.map((item,index)=>{
                        return (
                            <div key={"_profile_edu_"+item.id}>
                                <Box sx={{padding:1}}>
                                    <Grid mx={{marginTop:1}} container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>学校: {item.college}</Grid>
                                        <Grid item>{commonUtil.getYearMonth(item.eduStart)}至{commonUtil.getYearMonth(item.eduEnd)}</Grid>
                                    </Grid>
                                    <Grid mx={{marginBottom:1}}container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>学历: {commonUtil.getJsonValue(eduGradeList,item.eduLevel)}</Grid>
                                        <Grid item>专业: {item.major}</Grid>
                                    </Grid>
                                    <Grid mx={{marginBottom:1}}container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item xs={12}>在校经历: {item.remark}</Grid>
                                    </Grid>
                                </Box>
                                <Divider/>
                            </div>
                        )
                    })}
                </Paper>
               
                <Paper sx={{padding:2,marginTop:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align='center'>工作经历</Typography>
                        </Grid>
                    </Grid>
                    {workList && workList.length>0 && workList.map((item,index)=>{
                        return (
                            <div key={"_profile_work_"+item.id}>
                                <Box sx={{padding:1}}>
                                    <Grid mx={{marginTop:1}} container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>公司名称: {item.enName}</Grid>
                                        <Grid item>{commonUtil.getYearMonth(item.workStart)}至{commonUtil.getYearMonth(item.workEnd)}</Grid>
                                    </Grid>
                                    <Grid mx={{marginTop:1}} container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>职位名称: {item.jobTypeSubName}</Grid>
                                        <Grid item>所属部门: {item.department}</Grid>
                                    </Grid>
                                    <Grid mx={{marginBottom:1}}container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item xs={12}>工作内容: {item.remark}</Grid>
                                    </Grid>
                                </Box>
                                <Divider/>
                            </div>
                        )
                    })}
                </Paper>

                <Paper sx={{padding:2,marginTop:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align='center'>项目经历</Typography>
                        </Grid>
                    </Grid>
                    {projectList && projectList.length>0 && projectList.map((item,index)=>{
                        return (
                            <div key={"_profile_project_"+item.id}>
                                <Box sx={{padding:1}}>
                                    <Grid mx={{marginTop:1}} container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item>项目名称: {item.proName}</Grid>
                                        <Grid item>{commonUtil.getYearMonth(item.proStart)}至{commonUtil.getYearMonth(item.proEnd)}</Grid>
                                    </Grid>
                                    <Grid mx={{marginBottom:1}}container alignItems="center" justifyContent={"space-between"}>
                                        <Grid item xs={12}>项目描述: {item.remark}</Grid>
                                    </Grid>
                                </Box>
                                <Divider/>
                            </div>
                        )
                    })}
                </Paper>                
            </DialogContent>
            <DialogActions>
                <Button  onClick={closeProfileModel}>关闭</Button>
            </DialogActions>
        </Dialog>
    )
}


const mapStateToProps = (state) => {
    return {
        appReducer: state.AppReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    closeProfileModel :()=>{
        dispatch({type: AppActionType.setProfileUserId, payload: 0});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal)